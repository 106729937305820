//trigger bug scss
import "../sass/pubhubsite.scss";

import "regenerator-runtime/runtime";
import react from "react"
//import reactDom from 'react-dom'
import dayjs from 'dayjs';

import SiteEngine from './siteEngine/SiteEngine';

import "Common/utility/element"
import {getCookies, setCookie, getCookie, deleteCookie, setCiscoCookie} from './common/utility/cookie'
import {smoothScroll, scrollTo} from './common/utility/dom';
import {loadJSFile, loadCSSFile, QueryString} from './common/utility/utils'
import {fetchBlogs/*, getCreatorAvatar*/} from './common/utility/blog'
import {logged, loginRequired, onLoaderReady} from './common/utility/socialLogin'
import {subscribe, checkSubscriptionStatus} from './common/utility/subscription'

import {registerWidget} from "./common/base/WidgetLoader"

import ConfigParser from './common/utility/configParser';
import Element from '../widgets/basic/element';
import wrapWidget from '../widgets/basic/wrapper';
import {registerElement} from './common/utility/customElements'

import {getParsedHash, generateHash, updateHash, replaceHash} from './common/utility/hash'

import Alert from 'react-s-alert';

// Hmmmmm
window.Zepto = window.jQuery

class PubHub {
    static env = window.PUBHUB_ENV || CONFIG['ENV'] || 'staging';

    static _create(cfg, cls) {
        let instance = new cls();

        //set container first
        if (cfg["container"]) {
            instance.container = cfg["container"];
            delete cfg["container"];
        }

        //copy config and delete that from cfg
        // to set config after set all properties
        var _config;
        if (cfg["config"]) {
            _config = {...cfg["config"]};
            delete cfg["config"]
        }

        // set properties
        for (let i in cfg) {
            instance[i] = cfg[i];
        }


        // set config
        if (_config) {
            for (let i in _config) {
                instance.updateConfig(i, _config[i])
            }
        }

        window.PubHub.instance = instance;

        return instance
    }

    static createSitePage(cfg) {
        try {
            const instance = PubHub._create(cfg, SiteEngine);
            window.PubHubSiteInstance = instance;
            return instance

        } catch (err) {
        }
    }

    static get APIHost() {
        return PubHub.env == "production" ? "https://devnet.cisco.com" : "https://devnet-testing.cisco.com";
    }

    static get communityAPIHost() {
        return "https://communities.devnetcloud.com";
        //return location.host.indexOf("cisco.com") !== -1 ? "https://communities.devnetcloud.com" : "https://communities.testing.devnetcloud.com";
    }

    static get learningLabAPIHost() {
        return this.APIProxy + "https://learninglabs.cisco.com";
    }

    static get DevNetCreationAPI() {
        return "https://devnetapps.cisco.com/devnetcreation-data";
    }

    static get APIProxy() {
        return PubHub.env !== "production" ? "https://pubhub.cisco.com/app/project/proxy/?url=" : "";
    }

    static get smartsheetHost(){
        return PubHub.env !== "production" ? "https://d2o5w3xyvxcs9z.cloudfront.net" : "https://dcl3wxcscqm28.cloudfront.net";
    }

    static get SDKURL() {
        try {
            var sdk_js = document.querySelector("[src*='pubhub.js']");
            if (sdk_js) {
                var url = sdk_js.getAttribute("src").split("?")[0];
                return url.substr(0, url.length - 9);
            }

        } catch (e) {

        }
    }

}

const cookie = {
    getCookies: getCookies,
    setCookie: setCookie,
    getCookie: getCookie,
    deleteCookie: deleteCookie,
    setCiscoCookie: setCiscoCookie
};

function registerLocalWidgets(widgets, prefix = 'phex-') {
    for (let selector in widgets) {
        registerElement(selector, widgets[selector], prefix);
    }
}

PubHub.util = {
    smoothScroll: smoothScroll,
    scrollTo: scrollTo,
    cookie: cookie,
    loadJSFile: loadJSFile,
    loadCSSFile: loadCSSFile,
    fetchBlogs,
    // fetchBlogAuthorAvatar: getCreatorAvatar,
    dayjs: dayjs,
    QueryString,
    socialLogin: {
        logged: logged,
        loginRequired: loginRequired,
        onLoaderReady: onLoaderReady
    },
    localWidget: {
        configParser: ConfigParser,
        element: Element,
        wrapWidget: wrapWidget,
        registerWidgets: registerLocalWidgets
    },
    hash: {
        get: getParsedHash,
        generate: generateHash,
        update: updateHash,
        replace: replaceHash
    },
    subscription: {
        subscribe: subscribe,
        check: checkSubscriptionStatus
    },
    alert: Alert,
    React: react,
    //ReactDOM:reactDom
};


PubHub.registerWidget = registerWidget;

window.PubHub = PubHub;

//legecy， delete
window.PubHubCookie = cookie;

// redirect "https://devnet.cisco.com" to "https://developer.cisco.com"
// (function () {
//     if (window.location.origin === "https://devnet.cisco.com") {
//         window.location.href = window.location.href.replace("devnet.", "developer.");
//     }
// })();
